
import {defineComponent} from 'vue'
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import ServicePaymentStatus from "@/views/service/payment/ServicePaymentStatus.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ServicePaymentViewCard",
  components: {ServicePaymentStatus, CurrencyFormat, Row},
  props: {
    payment: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  },
})
