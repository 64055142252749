
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "ServicePaymentStatus",
  props: {
    status: {type: String, required: true}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'PENDING':
          data.value = {name: 'Pending', clazz: 'primary'}
          break
        case 'RECEIVABLE':
          data.value = {name: 'Receivable', clazz: 'info'}
          break
        case 'RECEIVED':
          data.value = {name: 'Received', clazz: 'success '}
          break
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
