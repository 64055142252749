
import {computed, defineComponent, onMounted, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import {CompanySelect, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";
import ServicePaymentViewCard from "@/views/service/payment/ServicePaymentViewCard.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {SERVICE_PAYMENT_STATUS} from "@/core/config/Constant";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ServicePaymentSearch",
  components: {
    EntityLoading,
    BaseSelect,
    ServicePaymentViewCard,
    Cards,
    Entities,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Client Fees',
        [
          {link: false, router: '', text: 'Client Fees'}
        ]
      )
    })
    const page = computed(() => store.state.ServicePaymentModule.page);
    const objectFilter = ref({companyId: '', status: ''});
    return {
      page,
      objectFilter,
      ...CompanySelect(),
      SERVICE_PAYMENT_STATUS,
      ...LoadFilterObjects(Actions.LOAD_PAYMENT_SERVICES, objectFilter.value, ['company', 'caze', 'service'])
    }
  },
  methods: {
    onDiscard() {
      this.objectFilter.companyId = '';
      this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.objectFilter)
    }
  }
})
